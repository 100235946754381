const markdown = `
### We are inspired by ...
 
![](https://m.media-amazon.com/images/S/aplus-media-library-service-media/e3e5cc34-02bb-4d3b-8991-2afe0c40cca9.__CR0,0,970,300_PT0_SX970_V1___.png)
wherein they highlight the urgency of creating **planetary institutions that deal with planetary problems** that nation states are incapable of, or antithetical to, solving. 

They suggest two such institutions to start, for **climate and pandemics**.  They also acknowledge the difficulty of manifesting anything so ambitious. 

### We propose ...
... an ***extitution*** (more on that below) to address a **third planetary problem**, in fact, a *meta-problem*: **our broken politics**. 

**Our political systems do not serve all of humanity**, much less the natural systems our survival depends on. They are **driven by national priorities, polluted by misinformation, captured by capital**, and **bound by constitutions written for a different millenium**. 

We believe a **planetary political union/party**, dedicated to manifesting a "[world that works for all](https://medium.com/basic-income/can-we-create-a-world-that-works-for-all-if-the-elites-in-charge-don-t-want-us-to-5eed5994c6be)", is both **critical and doable**.


Using innovative technology and great people, we can grow and govern a new and powerful organizational form, **planetary in scale** and **capable of acting emergently** within, yet independent of, current systems. Hence an ***extitution***.

### What is it?
**Planetary Beings** (working title) is using social graph technology to weave together **a planetary constituency** dedicated to **regeneration** of the planet and to exercising the **collective power of 100s of Ms** to that end. 

There is **currently no significant planetary political party**, regardless of orientation. The estimated total size of all Green Parties worldwide is less than one million (e.g. 244k in the US). 

Meanwhile, **[bioregionalism is becoming the new paradigm](https://medium.com/oneearth/a-brief-history-of-bioregions-and-bioregionalism-in-scholarly-literature-ea141f9f480f)** for how we organize planetarily. 

We intend to be larger than the Greens very quickly, through **network effects**; large enough to be **globally significant**, and to **affect the US mid-term elections** in 2026.


### How? 
Citizenship grows as a social graph, starting from **exemplary leaders who embody the desired worldviews, principles, and intent**.

Citizenship expands through invitation based on trust and respect, ensuring **social coherence around principles and platform**, and creating **chains of accountability from all participants back to the initiators**.


Citizens contribute to the evolution of the party platform, which tells the world what we are about, and attracts more who align.


See more detail under **Instructions**

### Who? 
The **initiating kernel** comes from the following partners:

* [Emerge Network](https://www.whatisemerging.com/)
* [Common.Earth](https://common.earth/)
* [FutureEarth](https://futureearth.org/)
* [GaiaNet](https://www.gaianet.earth/)
* [Global Regen Colab](https://www.grc.earth/)
* [Biofi.earth](https://www.biofi.earth/)

### Governance
This is uncharted territory, but the **initiators** (above) are the right people to navigate it. We have lots of knowledge and tools to get it right, and a clear, simple overall goal to scope the effort.

The first 1000 citizens will elect a governing board who will then be responsible for evolving the next layer of governance. No money raised will be spent until approved by the first 1000 participants.

`;

export default markdown;
