/** NB: THIS IS TEMPORARY, SHOULD BE ROLLED INTO A GENERAL PEOPLE SEARCH, e.g. SearchNetwork */
import React, { useEffect, useState, useMemo, useRef, useCallback, useReducer } from 'react';
import { useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import { CSVLink, CSVDownload } from 'react-csv';
import {
  Box,
  HStack,
  VStack,
  Input,
  Heading,
  Text,
  Spinner,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast,
  Link
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import styled from '@emotion/styled';
// import _ from 'lodash';

import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import useGroups from 'hooks/useGroups';
import usePeopleNetwork from 'hooks/usePeopleNetwork';
import UserAuth from 'components/User/UserAuth';
import Controls, { EdgeControls, SocialControls } from './ShowGroupControls'; // ToolsSelector

import { graph_normalize } from 'utils/graphNormalize';
import { getNetworkData } from 'shared/config';
import GroupSelector from 'components/Segments/GroupSelector';
import PeopleSelector from 'components/Segments/PeopleSelector';
import NetworkTabs from 'components/Networks/NetworkTabs';
import { dropNodeIdPrefix } from 'utils';

import { setCurrentGroupId } from 'redux/actions/groups';
import LocalStorage from 'utils/LocalStorage';
import { readFile, writeFile } from 'utils/fileReadWrite';

const Instructions = props => {
  return (
    <Accordion className='accordion' allowToggle width={['90vw', '82vw', '60vw']}>
      <AccordionItem backgroundColor={'#eee'}>
        <h2>
          <AccordionButton>
            <Text fontSize='lg' fontWeight={'bold'}>
              Search Instructions
            </Text>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} backgroundColor={'#eee'}>
          <VStack alignItems={'left'}>
            <Text fontSize='14px' paddingTop={'10px'}>
              Search for <em>People</em> by name. Mouseover names for more details.{' '}
            </Text>

            <Text fontSize='14px' paddingTop={'10px'}>
              Click to recenter on them and see their neighbors (equivalent to searching for them.)
            </Text>
            <Text fontSize='14px' paddingTop={'10px'}>
              <em>Search Description</em> matches user descriptions from their profiles.
            </Text>
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const ShowGroup = () => {
  const { nooUser, currentNetworkData } = useNoo('ShowGroup');
  const network = currentNetworkData;
  const netname = network.id;
  const show_controls = false;
  const show_social_controls = false;
  const isNoonet = netname == 'noonet';
  const isMastodon = netname == 'ph';
  const isGreencheck = netname == 'greencheck';
  const isWho = netname == 'whoknows';
  const hasChecks = true; // isMastodon || isWho;

  const { nooCommandDirect } = useNooApi();

  // const { nooCommand, nooCommandDirect, nooCommandResponse } = useNooApi();
  const { currentGroupId, setGroupId, groupSelector, currentGroupName } = useGroups({
    from: 'ShowGroup'
  });
  const {
    currentNetwork: graph,
    isRequesting: fetching,
    currentProfile,
    // currentNetworkRaw,
    getNetwork,
    getNetworkEdges,
    getCommunity,
    searchNetwork,
    getSpecificUsers,
    setPeopleNetworkData,
    totalMatches,
    getPenumbra
  } = usePeopleNetwork();

  var params = new URLSearchParams(useLocation().search);
  const [myStorage, setMyStorage] = useState(null);
  // wasn't in use, commenting out so that is clear:
  // const { group_id } = useParams();
  // const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState(null);
  // const [profileDataToShow, setProfileDataToShow] = useState(null);
  const [claimedOnly, setClaimedOnly] = useState(false);
  const [direction, setDirection] = useState('both');
  const [requiredSocial, setRequiredSocial] = useState('mastodon');
  const [degrees, setDegrees] = useState(1);
  const [localData, setLocalData] = useState({ desc: '', loc: '' }); // form values
  const [userId, setUserId] = useState();
  const [userDisplay, setUserDisplay] = useState(null);
  const [maxNodes, setMaxNodes] = useState(params.get('max_nodes') || 200);

  // const [graph, setGraph] = useState({});
  // const [friends, setFriends] = useState([]);
  const [networkGraphFullScreen, setNetworkGraphFullScreen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [showingChecked, setShowingChecked] = useState(false);
  const [myFollows, setMyFollows] = useState(null);
  const [checked, setChecked] = useState(null);
  let inTypes = params.get('types');
  inTypes = inTypes?.split(',') || [];

  const _HIDE_SEARCH_INTERFACE = params.has('hide');

  const [linkTypes, setLinkTypes] = useState(inTypes);
  const history = useHistory();
  const [myMastServer, setMyMastServer] = useState(null);
  const serverRef = useRef();

  const toast = useToast();

  const _setUserData = useCallback(
    (userId, displayName) => {
      setGroupId(null);
      setUserId(userId);
      if (displayName) {
        setUserDisplay(displayName);
      }
      const logged_in = nooUser?.person?._id;
      getNetwork({ userId, limit: maxNodes, direction, degrees, logged_in, types: linkTypes });
    },
    [
      setGroupId,
      setUserId,
      setUserDisplay,
      getNetwork,
      maxNodes,
      degrees,
      direction,
      nooUser,
      linkTypes
    ]
  );

  const loadUserOrGroupOnce = useRef(null);
  const inUserId = params.get('user');
  const inGroupId = params.get('group');
  const inQuery = params.get('query');

  useEffect(() => {
    if (nooUser && !loadUserOrGroupOnce?.current) {
      if (inUserId) {
        // use the function that is already working for this:
        loadUserOrGroupOnce.current = true;
        _setUserData('Persons/' + inUserId, '');
        setCurrentGroupId(null);
      }
      if (inGroupId) {
        const gId = 'Groups/' + inGroupId;
        loadUserOrGroupOnce.current = true;
        setUserId(null);
        setCurrentGroupId(gId);
        setGroupId(gId);
        getNetworkEdges({
          groupId: gId,
          direction,
          limit: maxNodes,
          personId: nooUser?.person?._id
        });
        // getCommunity({ groupId: gId, personId: nooUser?.person?._id, direction, limit: maxNodes });
      }
      if (inQuery) {
        let query_dict = JSON.parse(inQuery);
        loadUserOrGroupOnce.current = true;
        setUserId(null);
        setCurrentGroupId(null);
        const filters = null;
        query_dict = Object.fromEntries(Object.entries(query_dict).filter(([_, v]) => v));
        setUserDisplay(JSON.stringify(query_dict));
        searchNetwork(query_dict, filters);
      }
    }
  }, [
    inUserId,
    userId,
    inGroupId,
    currentGroupId,
    _setUserData,
    getNetworkEdges,
    direction,
    nooUser,
    maxNodes,
    inQuery,
    searchNetwork,
    linkTypes,
    setGroupId
  ]);

  useEffect(() => {
    const prof = currentProfile?.data?.profile || {};
    const displayName = prof?.fullname || prof?.displayName;
    if (displayName) {
      setUserDisplay(displayName);
    } else if (currentGroupName) {
      setUserDisplay(currentGroupName);
    }
  }, [currentProfile, currentGroupName]);

  const onFieldUpdate = e => {
    setGroupId(null);
    let { value: val, name: field } = e.target;
    const myLocalData = {
      ...localData
    };
    // console.log('updating', field, val, localData);
    let valid = null;
    switch (field) {
      case 'max':
        try {
          valid = parseInt(val === '' ? 0 : val);
        } catch (e) {
          console.log('error number input', e);
        }
        break;
      default:
        valid = val;
    }
    if (valid != null) {
      myLocalData[field] = valid;
      setLocalData(myLocalData);
    } else {
      console.log('invalid value', field, val);
    }
  };

  // const showSizes = data => {
  //   console.log(
  //     'graph data',
  //     JSON.stringify(data).length,
  //     JSON.stringify(data.profiles).length,
  //     JSON.stringify(data.edges).length,
  //     JSON.stringify(data.profiles[0])
  //   );
  //   const lens = _.map(data.profiles, one => {
  //     const len = JSON.stringify(one).length;
  //     if (len > 1400) {
  //       console.log(one);
  //     }
  //     return len;
  //   });
  //   console.log('profile lengths', lens);
  // };

  const display = useMemo(() => {
    return userDisplay || currentGroupName || '';
  }, [currentGroupName, userDisplay]);

  // TODO: discuss what this is used for, and if it's needed:
  // useEffect(() => {
  //   const filters = claimedOnly ? { exclude: { provisional: true } } : null;
  //   const new_graph = currentNetworkRaw ? graph_normalize(currentNetworkRaw, filters, null, false) : null;
  //   setGraph(new_graph);
  //   setGroupName(searchData?.group?.data?.fullname?.split('_').join(' '));
  //   setFriends(searchData?.profiles.map(one => one.profile));
  // }, [currentNetworkRaw, claimedOnly]); // , maxNodes

  // const userEnter = node => {
  //   setProfileDataToShow(node);
  // };

  // const userLeave = node => {
  //   setProfileDataToShow(null);
  // };

  const toggleClaimed = e => {
    // whether or not to show provisional users
    setClaimedOnly(e.target.checked);
  };

  const changeMax = e => {
    const maxNodes = e.target.value;
    try {
      setMaxNodes(parseInt(maxNodes));
    } catch (e) {
      console.log('Non-integer max value', maxNodes);
    }
  };

  const onSelectGroup = (event, groupId) => {
    // console.log('ShowGroup seelected group', groupId);
    if (groupId) {
      // groups hook does this internally when using GroupSelector
      // setGroupId(groupId);
      setUserId(null);
      loadUserOrGroupOnce.current = false;
      setUserDisplay(currentGroupName);
      setShowingChecked(false);
      const url = makeUrl(`group=${dropNodeIdPrefix(groupId)}`);
      history.push(url);
    }
  };
  const networkOfChecked = useCallback(
    selected => {
      console.log('getting network', selected);
      if (!fetching && selected?.length > 0) {
        getPenumbra({ pids: selected, limit: 200, depth: 1 });
      }
    },
    [fetching, getPenumbra]
  );

  const penumbraOfChecked = useCallback(
    (selected, depth) => {
      console.log('getting penumbra', selected);
      depth = depth || 2;
      if (!fetching && selected?.length > 0) {
        getPenumbra({ pids: selected, limit: 200, depth });
      }
    },
    [fetching, getPenumbra]
  );

  // TODO: does this need to check for groupSelector?
  // that restricts the auto-call on load
  // to only apps that can change groups...
  /*   useEffect(() => {
    // console.log('current group ', currentGroupId);
    if (currentGroupId && !graph && groupSelector) {
      //
      getNetworkEdges({ groupId: currentGroupId, direction });
    }
  }, [currentGroupId, direction, getNetworkEdges, graph, groupSelector]); */

  const norm_mast = strng => {
    // TBD test and fix
    let res = strng;
    if (strng) {
      const parts = strng.split('/');
      if (parts.length > 1) {
        let nm = parts[1];
        const server = parts[0];
        res = [nm, server].join('@');
      }
      if (res[0] == '@') res = res.slice(1);
    }
    return res;
  };

  useEffect(() => {
    if (hasChecks && showingChecked && graph) {
      const force = true;
      // myStorage.sync();
      const checked = myStorage.getItem('checked_users'); // not tested
      let csv = graph.nodes.filter(node => {
        return checked.includes(node.id); // node.mastodon &&
      });
      let out = ['PersonId, linkedin, Mastodon,Show boosts,Notify on new posts,Languages'];
      csv.forEach(one => {
        const mast = one.mastodon?.username || one.mastodon;
        const handle = one.handle || one.linkedin?.username;
        out.push([one.id || one._id, handle, mast ? norm_mast(mast) : null, true, true].join(','));
      });
      setCsvData(out.join('\n'));
    }
  }, [setCsvData, showingChecked, graph, myStorage, checked, hasChecks]);

  const onSelectUser = user => {
    const user_id = user?.value;
    if (user_id) {
      loadUserOrGroupOnce.current = false;
      setUserDisplay(user.label);
      setShowingChecked(false);
      const url = makeUrl(`user=${dropNodeIdPrefix(user_id)}`);
      history.push(url);
    }
  };

  const clickFriend = user => {
    loadUserOrGroupOnce.current = false;
    const fname = user?.fullname || user?.data?.profile?.fullname;
    const url = makeUrl(`user=${dropNodeIdPrefix(user._id || user.id)}`);
    history.push(url);
    setUserDisplay(fname);
    setShowingChecked(false);
  };

  const makeUrl = primary => {
    let url = `/search_network/?${primary}`;
    if (linkTypes?.length > 0) {
      url += `&types=${linkTypes.join(',')}`;
    }
    return url;
  };

  const onSearchSubmit = e => {
    let query_dict = (({ desc, loc }) => ({ desc, loc }))(localData);
    query_dict = Object.fromEntries(Object.entries(query_dict).filter(([_, v]) => v));
    const filters = isMastodon ? ['person.data.mastodon.username != null'] : null;
    if (query_dict || filters) {
      searchNetwork(query_dict, filters);
      // TODO: verify it's correct to unset the groupId here, this does it globally
      setGroupId(null);
      setUserDisplay(JSON.stringify(query_dict));
      setShowingChecked(false);
      const url = makeUrl(`query=${JSON.stringify(query_dict)}`);
      history.push(url);
    }
  };

  useEffect(() => {
    if (nooUser) {
      setMyStorage(new LocalStorage(netname, nooUser.person._id));
    }
  }, [setMyStorage, netname, nooUser]);

  const onChecked = useCallback((a, b, c) => {
    // console.log('showGroup oncheck', a, b, c);
  }, []);

  useEffect(() => {
    if (nooUser && myStorage) {
      setMyMastServer(myStorage.get('server') || 'mastodon.social');
    }
  }, [myStorage, nooUser]);

  const showChecked = selected => {
    if (hasChecks && selected?.length > 0) {
      getSpecificUsers(selected);
      // TODO: verify it's correct to unset the groupId here, this does it globally
      setGroupId(null);
      setUserDisplay('My Selections');
      setShowingChecked(true);
    }
  };

  const checkAll = data => {
    setChecked(data);
  };

  const onDescription = e => {
    const query = e.target.value.trim();
  };

  const toggleFull = () => {
    setNetworkGraphFullScreen(!networkGraphFullScreen);
  };

  /*   <Link as={RouterLink} to='/invite_bulk'>
  .
</Link> */
  const StandardGuide = () => {
    return <Instructions />;
  };
  const onUpdateServer = e => {
    const parts = e.target.value.split('//');
    let val = parts ? parts[parts.length - 1] : '';
    const testr = new RegExp(/\w+\.\w+/);
    if (testr.test(val)) {
      setMyMastServer(val);
      myStorage.set('server', val);
      toast({
        title: 'Server changed to ' + val,
        description: 'Your Mastodon Follow clicks with be directed there.',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } else {
      toast({
        title: 'Must be in the form foo.bar, e.g. mastodon.social',
        description: 'Your Mastodon Follow clicks with be directed there.',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  const onLinkTypeChange = types => {
    const typs = Array.from(types);
    setLinkTypes(typs);
    const repl = new RegExp('[?&]types=[,A-Z]*', 'g');
    const parts = location.href.split('?');
    let url = parts.length == 2 ? parts[1] : '';
    url = '?' + url.replace(repl, '');
    if (typs?.length > 0) {
      url += `&types=${typs.join(',')}`;
    }
    loadUserOrGroupOnce.current = false;
    history.push(url);
  };

  const onEnter = e => {
    if (e.which == 13) {
      console.log('entered', serverRef);
      serverRef.current.blur();
    }
  };

  const ServerInput = props => {
    return (
      <HStack mt='8px' title='Set this to your instance to make individual following fast.'>
        <Text fontSize='lg' fontWeight={'bold'} color='#c33'>
          My Instance/Server
        </Text>
        <Input
          ref={serverRef}
          bg='white'
          mr='10px'
          height='30px'
          width='200px'
          _focus={{ outline: '0' }}
          type='text'
          id='server'
          name='server'
          placeholder={props.server}
          defaultValue={props.server}
          // onChange={onUpdateServer}
          onBlur={onUpdateServer}
          onKeyUp={onEnter}
        />
      </HStack>
    );
  };

  const MastodonGuide = () => {
    return (
      <Accordion
        className='accordion'
        allowToggle
        width={['90vw', '82vw', '60vw']}
        backgroundColor='#eee'
      >
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as='span' flex='1' textAlign='left'>
                <Text fontSize='lg' fontWeight={'bold'}>
                  Important
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontSize='14px' paddingTop={'10px'}>
              You should set your Instance/Server so that the Mastodon links you click on will go
              there, making following fast and easy.
            </Text>
            <Text fontSize='14px' paddingTop={'10px'}>
              There are two ways to follow people: one at a time, and in bulk via CSV download. You
              do the former by mousing over their name and clicking their Mastodon link (after
              setting your Server).
            </Text>
            <Text fontSize='14px' paddingTop={'10px'}>
              The latter is not entirely reliable (out of our control) because different instances
              treat them differently. We suggest trying CSV export, then import on your instance. If
              that doesn&apos;t work, you can follow them individually.
            </Text>
            <Text fontSize='14px' paddingTop={'10px'}>
              All your checked users stay with the tab you&nbsp;re on until you clear them, so you
              can build up a collection over time. They stay local to you; we do not store them
              anywhere else. Maybe save them periodically using Download CSV.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as='span' flex='1' textAlign='left'>
                <Text fontSize='lg' fontWeight={'bold'}>
                  General
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontSize='14px' paddingTop={'10px'}>
              Click the checkbox on users you want to follow. Those are kept in your browser tab
              localStorage until you clear them.
            </Text>
            <Text fontSize='14px' paddingTop={'10px'}>
              To export your checked users to CSV, click Select Checked Users, then Download as CSV.
            </Text>
            <Text fontSize='14px' paddingTop={'10px'}>
              Click the Mastodon link to follow them individually. Be sure to set your server name
              to make individual following fast.
            </Text>
            <Text fontSize='14px' paddingTop={'10px'}>
              Search by <em>Topic</em> for some broad subjects.
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };
  const default_tab = 0; // list view // isGreencheck || isMastodon  ? 1 : 0;
  const noop = () => {};
  const tools_options = {
    // additions to SelectionTools
    /*     'choose one': noop,
    
    'clear selected': clearChecked,
    'save selected': saveChecked,
    'restore selected': restoreChecked, */
    'select all': checkAll,
    'show selected': showChecked,
    'first degree from selected': networkOfChecked,
    'second degree from selected': penumbraOfChecked
  };
  const onToolChange = (tool, selected) => {
    setChecked(selected);
    const action = tools_options[tool];
    // console.log('group action ontoolchange', tool, action, selected);
    if (action) {
      action(selected);
    }
  };

  const HdrText = () => {
    if (netname == 'netcoop') {
      return (
        <Box>
          <Text>
            We have simulated this vision at as if ~2000 people did what you are invited to do here,
            resulting in over 700k connections among 320k+ people. Explore it at the Network tab at
            <Link
              color='teal.500'
              paddingLeft={'5px'}
              isExternal
              href='https://nao.is/search_network'
            >
              NAO.is
              <ExternalLinkIcon />
            </Link>
          </Text>
          <Text>
            This version is the real thing, with {nooUser?.k_nodes} profiles, {nooUser?.k_edges}{' '}
            connections from {nooUser?.netcoop?.length} members, so far.
          </Text>
        </Box>
      );
    } else {
      return (
        <Box>
          <Text>
            This is a simulation of a Network Cooperative after about 2500 members have imported
            their Linkedin connections.
          </Text>{' '}
          <Text>
            Currently with {nooUser?.k_nodes} profiles, {nooUser?.k_edges} connections, and{' '}
            {nooUser?.k_groups} groups.
          </Text>
        </Box>
      );
    }
  };

  const Hdr2 = () => {
    return <Text>foo</Text>;
  };

  return (
    <Box>
      {isMastodon ? (
        <VStack alignItems={'left'}>
          <Text fontSize='14px'>
            See this &nbsp;
            <Link href='/public/images/StigMastodon_tutorial_480.mov' isExternal>
              How To video <ExternalLinkIcon mx='2px' />
            </Link>
            . Search 20k+ quality recent migrants to Mastodon from the bird site and elsewhere, on
            numerous topics.
          </Text>
          <MastodonGuide />
          <ServerInput server={myMastServer} />
        </VStack>
      ) : (
        <VStack alignItems={'left'}>
          {!_HIDE_SEARCH_INTERFACE && (
            <Box>
              <HdrText />
              <StandardGuide />
            </Box>
          )}
        </VStack>
      )}

      <UserAuth unauthorized='signin'>
        {!_HIDE_SEARCH_INTERFACE && (
          <VStack className='search_inputs' align='left' mb='0.5em' width={['100%', '100%', '70%']}>
            {groupSelector && isMastodon && (
              <HStack width='25%' className='input_box'>
                <Heading size='sm'>Choose Topic</Heading>
                <GroupSelector
                  callback={onSelectGroup}
                  label={'Choose Group'}
                  selected={currentGroupId}
                  render={'selector'}
                  nooUser={nooUser}
                  filters={['all']}
                />
              </HStack>
            )}
            <PeopleSelector
              onSelect={onSelectUser}
              sources={['linkedin']}
              inputPlaceholder={'Name as you know them...'}
            />
            {true && (
              <HStack className='input_box'>
                <HStack>
                  <Button onClick={onSearchSubmit} disabled={!(localData.desc || localData.loc)}>
                    Search {isMastodon ? 'Description' : 'Desc/Loc'}
                  </Button>
                  <Input
                    type='text'
                    placeholder='Description'
                    name='desc'
                    // value={localData?.desc}
                    // onChange={onFieldUpdate}
                    onBlur={onFieldUpdate}
                    size='sm'
                    width='140px'
                  />
                  {true && ( // !isMastodon
                    <Input
                      type='text'
                      placeholder='Location'
                      name='loc'
                      onBlur={onFieldUpdate}
                      size='sm'
                      width='140px'
                    />
                  )}
                </HStack>
              </HStack>
            )}

            {groupSelector && !isMastodon && (
              <HStack width='25%' className='input_box'>
                <Heading size='sm'>Choose Group</Heading>
                <GroupSelector
                  callback={onSelectGroup}
                  label={'Choose Group'}
                  selected={currentGroupId}
                  render={'selector'}
                  nooUser={nooUser}
                  filters={['all']}
                />
              </HStack>
            )}
            <EdgeControls onChange={onLinkTypeChange} selected={linkTypes} />
          </VStack>
        )}
        {fetching && <Spinner />}

        {show_controls && (
          <Controls
            onFieldUpdate={onFieldUpdate}
            claimedOnly={claimedOnly}
            toggleClaimed={toggleClaimed}
            changeMax={changeMax}
            maxNodes={maxNodes}
            direction={direction}
            setDirection={setDirection}
          />
        )}
        {show_social_controls && (
          <SocialControls
            onFieldUpdate={onFieldUpdate}
            claimedOnly={claimedOnly}
            toggleClaimed={toggleClaimed}
            changeMax={changeMax}
            maxNodes={maxNodes}
            requiredSocial={requiredSocial}
            setRequiredSocial={setRequiredSocial}
          />
        )}
        {display && <Heading size='md'>Results for: {display}</Heading>}
        <NetworkTabs
          graph={graph}
          maxNodes={200}
          onChecked={onChecked}
          clickFriend={clickFriend}
          default_tab={default_tab}
          // networkDisabled={nooUser ? false : true}
          userId={userId}
          isMastodon={isMastodon}
          myMastServer={myMastServer}
          total={totalMatches}
          tools={tools_options}
          onToolChange={onToolChange}
        />

        <div className='App-row listing' bg='white'></div>
      </UserAuth>
    </Box>
  );
};

export default ShowGroup;
