import { objectSort } from 'utils';

export const getOptionDecisionTallyAccum = (decision, tallyType = 'total') => {
  let total = 0;
  const { tally, tallyFunction } = decision;
  if (tally && tallyFunction && tally[tallyFunction]) {
    Object.values(tally[tallyFunction]).forEach(tally => {
      if (tally[tallyType]) {
        total += tally[tallyType];
      }
    });
  }
  return total;
};

// data model maybe is shifting a little,
// doing a multi-check to get a title
export const getOptionTitle = option => {
  return option.data?.label || option.data?.title;
};

export const getOptionTallyType = (decision, option, type = 'total') => {
  const { tallyFunction } = decision.question;
  if (decision.question?.tally?.[tallyFunction]?.[option._id]) {
    return decision.question.tally[tallyFunction][option._id][type];
  }
  return 0;
};

export const addTalliesToOptions = (decision, options) => {
  let vote_rollup = {}; // dict of votes per option id
  Object.entries(decision.answers || {}).forEach(pair => {
    const [personId, answer] = pair;
    const votes = answer.data?.vote;
    Object.entries(votes).forEach(pair2 => {
      const [optid, weight] = pair2;
      let current = vote_rollup[optid] || {};
      current[personId] = weight;
      vote_rollup[optid] = current;
    });
  });
  const my_answers = decision.my_answer?.[0] || {};
  const my_votes = my_answers.data?.vote || {};
  return options.map(option => {
    option.total = getOptionTallyType(decision, option, 'total');
    option.count = getOptionTallyType(decision, option, 'count');
    option.votes = vote_rollup[option._id] || {};
    option.sort_label = option.data.label.toLowerCase();
    option.is_mine = my_votes[option._id] || 0;
    return option;
  });
};

export const optionGetMyVote = (answerSet, option) => {
  return answerSet?.[option._id];
};

export const getOptionTotalCharLength = totals => {
  let max = 0;
  if (totals?.length) {
    totals.forEach(total => {
      if (max < total) {
        max = total;
      }
    });
  }
  return (max && max.toString().length + 1) || 1;
};
