import React, { useState } from 'react';
import {
  Flex,
  Box,
  HStack,
  Switch,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Link
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import styled from '@emotion/styled';
import _ from 'lodash';
import ShowForceNetwork from 'components/Networks/ShowForceNetwork';
import ProfilesResults from 'components/Search/Results/Profiles';
import ProfilesResultsTwitter from 'components/Search/Results/ProfilesTwitter';
import ShowNetwork from 'components/Networks/ShowNetwork';

export const NetworkBox = styled(Box)`
  width: 100%;
  background-color: white;
  border: 1px solid lightgrey;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  position: ${props => (props.position ? 'absolute' : 'initial')};
`;

const NetworkTabs = props => {
  const {
    graph,
    default_tab,
    isMastodon,
    clickFriend,
    userId,
    onChecked,
    maxNodes,
    myMastServer,
    networkDisabled,
    tools, // checkbox management
    onToolChange
  } = props;
  const [networkGraphFullScreen, setNetworkGraphFullScreen] = useState(false);
  const [tab, setTab] = useState(default_tab);
  const toggleFull = () => {
    setNetworkGraphFullScreen(!networkGraphFullScreen);
  };

  const noop = () => {
    console.log('not implemented yet in this case.');
  };
  const total = props.total || graph?.nodes?.length;

  const saveTab = t => {
    setTab(t);
  };

  return (
    <Box sx={{ padding: '10px', border: '1px solid grey', bg: '#fafafa' }}>
      {total && (
        <Heading size='md' paddingLeft={'12px'}>
          {typeof total == 'number' ? (
            <Text>Result: {total} total matches</Text>
          ) : (
            <Text>{total}</Text>
          )}
        </Heading>
      )}
      <Tabs
        variant='enclosed-colored'
        colorScheme='green'
        onChange={saveTab}
        defaultIndex={default_tab}
      >
        <TabList backgroundColor='#ffffff'>
          <Tab>
            <strong>List View</strong>
          </Tab>
          {!networkDisabled && (
            <Tab isDisabled={networkDisabled}>
              <strong>
                <Tooltip label={networkDisabled ? 'Requires log in' : ''}>Network View</Tooltip>
              </strong>
            </Tab>
          )}
          {/*           <Tab>
            <strong>Text View</strong>
          </Tab> */}
        </TabList>

        <TabPanels backgroundColor='#eee'>
          <TabPanel>
            {graph && (
              <Box w={'100%'}>
                <Text>
                  The numbers in the Connections column left are the{' '}
                  <Link href={'https://en.wikipedia.org/wiki/Global_majority'} isExternal>
                    <b>
                      <em>network centrality</em>
                    </b>
                    <ExternalLinkIcon mx='2px' />
                  </Link>{' '}
                  of that person (i.e. their interconnections) within the set.{' '}
                </Text>
                <Text>
                  Be sure to click <b>Network View</b> above to see those connetions.
                </Text>
                {isMastodon ? (
                  <ProfilesResultsTwitter
                    data={graph}
                    kind={'profiles'}
                    personId={userId}
                    onclick={clickFriend || noop}
                    onChecked={onChecked || noop}
                    server={myMastServer}
                  />
                ) : (
                  <ProfilesResults
                    data={graph}
                    kind={'profiles'}
                    personId={userId}
                    onChecked={onChecked || noop}
                    onclick={clickFriend || noop}
                    tools={tools}
                    onToolChange={onToolChange}
                  />
                )}
              </Box>
            )}
          </TabPanel>
          {true && ( // !isMastodon
            <TabPanel>
              <Accordion
                maxWidth={'500px'}
                backgroundColor={'#eee'}
                allowToggle
                /* width={width}
      onChange={onChange} */
              >
                <AccordionItem>
                  <Heading as='h2'>
                    <AccordionButton>
                      <Box as='span' flex='1' textAlign='left'>
                        <Text fontSize='lg' fontWeight={'bold'}>
                          Display Instructions
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Heading>
                  <AccordionPanel pb={4}>
                    <Text fontSize='14px'>
                      <strong>Scroll in/out to zoom</strong>; <strong>Click-drag to rotate</strong>.
                    </Text>
                    <Text fontSize='14px'>
                      Check <strong>Fullscreen</strong> to more efficiently meander through the
                      network.
                    </Text>
                    <Text fontSize='14px'>
                      <strong>Mouseover shows connections</strong> local to that person. Sorry, it
                      can be jumpy.
                    </Text>
                    <Text fontSize='14px'>
                      <strong>CAVEAT</strong>: Not optimized for phone, especially Portrait mode. If
                      viewing on the phone, use Landscape.
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Flex>
                {graph && tab == 1 && (
                  <NetworkBox position={!!networkGraphFullScreen}>
                    <HStack>
                      <Text as='span'>fullscreen</Text>
                      <Switch
                        onChange={toggleFull}
                        isChecked={!!networkGraphFullScreen}
                        size='md'
                      />
                    </HStack>
                    <ShowForceNetwork
                      graph={graph}
                      width={'100%'}
                      maxNodes={maxNodes}
                      node_click_cb={clickFriend}
                    />
                  </NetworkBox>
                )}
              </Flex>
            </TabPanel>
          )}
          {/* <TabPanel>{graph && <ShowNetwork graph={graph} />}</TabPanel> */}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default NetworkTabs;
