import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Flex, Box, Link, Image, Stack, Text, VStack, HStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import useFirebaseAuthLogout from 'hooks/useFirebaseAuthLogout';
import useNoo from 'hooks/useNoo';
import useNooUi from 'hooks/useNooUi';
import useMultiDomain from 'hooks/useMultiDomain';
import SelectNet from 'components/Networks/SelectNet';
import UserAuth from 'components/User/UserAuth';

// const NetworkImg = styled(Image)`
//   max-height: 120px;
//   width: auto;
//   max-width: 95%;
//   display: inline-block;
// `;

const HeaderFlex = styled(Box)`
  padding: 0.5em;
  font-size: calc(10px + 2vmin);
  @media screen and (max-width: 40em) {
    display: block;
    padding: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }
`;

const Header = () => {
  const { signOut } = useFirebaseAuthLogout();
  const { googleUser: user, currentNetworkData, nooUser } = useNoo('Heading');
  const netname = currentNetworkData.id;
  const { appSwitcher } = useMultiDomain();

  const { combinedTheme } = useNooUi();
  // console.log('combinedTheme combinedTheme combinedTheme combinedTheme');
  // console.log(combinedTheme);

  const { logo, logo_name /*, name_height, display*/ } = currentNetworkData;
  // let header_styles = {
  //   backgroundColor: combinedTheme?.colors?.headerBg
  // };
  const header_bg = combinedTheme.images?.header_bg;
  // console.log('HEADER user', nooUser);
  const is_claimed = true; // nooUser?.person?.data?.li;

  const normalSx = {
    color: combinedTheme.colors?.username,
    backgroundColor: 'rgba(255, 255, 255, 0.0)'
  };

  const highlitSx = {
    color: '#fff',
    backgroundColor: '#f00'
  };

  const nameSx = is_claimed ? normalSx : highlitSx;

  return (
    <HeaderFlex
      backgroundImage={header_bg && `url('${header_bg}')`}
      backgroundRepeat={header_bg && 'repeat-x no-repeat'}
      backgroundSize={header_bg && 'cover'}
      height={['110px', '110px', '150px']}
      padding={'0'}
      margin={0}
      className='container'
    >
      <HStack w='100%'>
        <Link
          w={['30%', '40%', '50%']}
          maxHeight='4em'
          as={RouterLink}
          to='/'
          color={combinedTheme?.colors?.topLink}
        >
          <Box
            display={['block', 'flex']}
            justify='space-between'
            fontSize='calc(10px + 2vmin)'
            height='auto'
            maxWidth={['200px']}
          >
            {logo && (
              <Image
                className='logo'
                maxWidth={['200px']}
                maxHeight={['4em', '90px', '120px']}
                src={logo}
                alt=''
              />
            )}
            <Box flex='1'>
              {logo_name && (
                <Image
                  maxHeight={['60px', '90px', '120px']}
                  className='logo_name'
                  src={logo_name}
                  alt=''
                  display={['none', 'none', 'block']}
                />
              )}
            </Box>
          </Box>
        </Link>
        {/*         <Text>
          Apologies: Database down for maintenance. Searches will not work til Tuesday 12/17.
        </Text> */}
        <Box w={['100%', '50%']}>
          <UserAuth inHeader={true}>
            <VStack justify='end' align='flex-end'>
              <Link as={RouterLink} to='/account'>
                <Text as='span' sx={nameSx} whiteSpace='nowrap' fontSize={['16px', '18px', '24px']}>
                  {user?.displayName || user?.fullname || 'set your username'}
                </Text>
              </Link>

              <Flex direction={['column', 'row']} align='flex-end'>
                {appSwitcher && (
                  <Box width='130px' ml='0' pt='0' fontSize='12px' mb='4px'>
                    <SelectNet />
                  </Box>
                )}
                <Button
                  textTransform='uppercase'
                  fontSize='12px'
                  p='0 10px'
                  mb='4px'
                  height='8'
                  size='md'
                  onClick={signOut}
                >
                  Sign out
                </Button>
              </Flex>
            </VStack>
          </UserAuth>
        </Box>
      </HStack>
    </HeaderFlex>
  );
};

export default Header;
