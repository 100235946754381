const markdown = `
### Our ask from you
You only need to do three things, which should take only 5-10 minutes at first. 


* **Participate in the polls** below - Suggest any missing alternatives, and vote for the ones you most value.
* **Get a GreenCheck ID** - This provides **proof of uniqueness** so only real humans participate, no bots or duplicate accounts
* **Invite** a few others you think align with the platform so far.

Voting in the polls requires starting from an invitation link from an existing participant.

### Expansion by invitation
Each participant with a GreenCheck ID gets a unique invitation link they share with others. Accepting an invitation creates a *relationship edge* from the inviter to the invitee. Such edges provide accountability, delegation, and numerous other capabilities we can take advantage of.

We're starting by leveraging LinkedIn. It has 890M users, including, for instance, 31M who have the word "environment" in their profile.

Starting from the initiating kernel of 100, if each participant invites 25 unique others, we get to over 1M in three hops. Our analytics show, on Linkedin, it's "four degrees of separation", not six. (Of course, non-LinkedIn users are welcome and can be invited in the same way.)


### GreenCheck
GreenCheck allows you to prove control over various attributes like phone, email, Linkedin, Github... This cuts down on the possibility of duplicate or bot accounts (aka *[sybil resistance](https://en.wikipedia.org/wiki/Sybil_attack)*).  It only takes a few minutes and allows us an extra layer of security.



`;

export default markdown;
