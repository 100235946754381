import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  Flex,
  Box,
  Button,
  Input,
  FormLabel,
  Textarea,
  Spinner,
  Select,
  Heading
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import usePeopleNetwork from 'hooks/usePeopleNetwork';

import UserAuth from 'components/User/UserAuth';

import { ContentSubpanel, WhiteBox } from 'components/Segments/Styled';
import NetworkTabs from 'components/Networks/NetworkTabs';

import FontAwesomeIcon from 'utils/FontAwesomeLoader';
import { bioregionIdFromName } from 'utils';
import { dropNodeIdPrefix } from 'utils';

/* FOR r IN Resources
  FOR p IN Persons
    FILTER 'sharebnb' in p.subnets && r.personId == p._id 
    RETURN r */

const SearchPenumbra = () => {
  const { token, nooUser } = useNoo();
  let { pids, depth = 2 } = useParams(); // either list of person keys or bioregion id
  depth = parseInt(depth);
  const bioregionId = bioregionIdFromName(pids);
  if (bioregionId) {
    pids = [];
  } else {
    pids = (pids || '').split(',').filter(x => x);
    pids = pids.map(_key => 'Persons/' + _key);
  }
  const { nooCommand, nooCommandResponse, nooCommandIsProcessing } = useNooApi();
  const {
    currentNetwork: graph,
    isRequesting: fetching,
    currentProfile,
    getPenumbra,
    getBioregion
  } = usePeopleNetwork();

  const [response, setResponse] = useState('{}');

  const [aqlQuery, setAqlQuery] = useState('aqlUsersPenumbra');
  const [result_kind, setResultKind] = useState('groups');
  const [responseHeight, setResponseHeight] = useState('100px');
  const responseRef = useRef(null);
  const selectorRef = useRef(null);
  const [graphData, setGraphData] = useState(null);
  const [currentUsers, setCurrentUsers] = useState(pids?.length > 0 ? pids : []);

  const onCallPenumbraSearch = useCallback(
    e => {
      console.log('getting penumbra', currentUsers);
      if (!fetching && currentUsers?.length > 0) {
        getPenumbra({ pids: currentUsers, limit: 200, depth });
      }
    },
    [currentUsers, depth, fetching, getPenumbra]
  );
  const onCallBioregionSearch = useCallback(
    e => {
      console.log('getting bioregion', bioregionId);
      if (!fetching && bioregionId) {
        getBioregion({ bio_id: bioregionId, limit: 100 });
      }
    },
    [bioregionId, fetching, getBioregion]
  );

  const setPids = e => {
    console.log(e.target.value);
    const pids = e.target.value.split(',');
    setCurrentUsers(pids.map(_key => 'Persons/' + dropNodeIdPrefix(_key)));
  };

  useEffect(() => {
    if (!fetching && !graph && currentUsers?.length > 0) {
      onCallPenumbraSearch();
    }
  }, [currentUsers, fetching, graph, nooUser, onCallPenumbraSearch]);
  return (
    <Box>
      {fetching && (
        <Box float='left'>
          <Spinner />
        </Box>
      )}
      <FormLabel htmlFor='pids'>Enter pids</FormLabel>
      <Textarea id='pids' name='pids' onBlur={setPids}></Textarea>
      <Button
        onClick={onCallPenumbraSearch}
        disabled={nooCommandIsProcessing || aqlQuery === '' || currentUsers?.length <= 0}
        iconright={FontAwesomeIcon({
          icon: ['fad', 'search'],
          style: { marginTop: '4px' }
        })}
      >
        Search Penumbra
      </Button>
      {bioregionId && (
        <Button
          onClick={onCallBioregionSearch}
          disabled={nooCommandIsProcessing || aqlQuery === '' || !bioregionId}
          iconright={FontAwesomeIcon({
            icon: ['fad', 'search'],
            style: { marginTop: '4px' }
          })}
        >
          Find in Bioregion
        </Button>
      )}

      <NetworkTabs graph={graph} maxNodes={200} default_tab={1} />
    </Box>
  );
};

export default SearchPenumbra;
