import React, { useState, useMemo } from 'react';
import { Box, HStack, Heading } from '@chakra-ui/react';
import usePeopleSearch from 'hooks/usePeopleSearch';
import AutoComplete from 'components/Segments/AutoComplete';
import { objectSort } from 'utils';

const PeopleSelector = props => {
  const { people, isSearching, clearPeopleResults, searchPeople } = usePeopleSearch();
  // bubbles the selected user back up to the consumer / parent:
  const [name, setName] = useState('');
  const chooseUser = selectedItem => {
    let { item } = selectedItem;
    const pid = item.value;
    const profile = people.find(one => one._id == pid || one.id == pid);
    if (profile) item = { ...item, ...profile };
    setName(item.label);
    props.onSelect(item);
  };

  const options = useMemo(() => {
    const allowed = props.sources ?? ['profile', 'linkedin'];
    if (people) {
      return people
        .filter(one => allowed.includes(one.source))
        .map(one => {
          return { value: one._id, label: one.fullname || one.displayName };
        })
        .sort(objectSort('label'));
    }
    return null;
  }, [people, props.sources]);

  return (
    <HStack className='input_box'>
      <Heading size={'sm'} color={props.disabled ? 'lightgray' : 'black'}>
        {props.label || 'People'}
      </Heading>
      <Box sx={{ border: props.disabled ? '1px solid lightgray' : '1px solid gray' }}>
        <AutoComplete
          isSearching={isSearching}
          options={options}
          onSelectOption={chooseUser}
          clearOptions={clearPeopleResults}
          searchOptions={searchPeople}
          defaultValue={name}
          // optional: can search on length of input, or space detection:
          searchOnSpace={false}
          searchOnLength={4}
          // these are defaults in the AC component, just doing show-n-tell here:
          // searchMsg='searching...'
          // typingMsg='keep typing...'
          inputPlaceholder={props.inputPlaceholder}
          disabled={props.disabled}
        />
      </Box>
    </HStack>
  );
};

export default PeopleSelector;
