import {
  SEARCH_PEOPLE_REQUEST_START,
  SET_PEOPLE_DATA,
  SET_PEOPLE_ERROR,
  CLEAR_PEOPLE_DATA
} from 'shared/constants/actionTypes';
import { nooCommandDirect } from './nooApi';
import _SERVICES from 'shared/constants/socialServices';

const searchPeopleRequestStart = () => ({ type: SEARCH_PEOPLE_REQUEST_START });

const setPeopleData = data => ({
  type: SET_PEOPLE_DATA,
  payload: data
});

const setPeopleError = error => ({
  type: SET_PEOPLE_ERROR,
  payload: error
});

const decorate_fullname = person_data => {
  const lookup = { linkedin: 'li', facebook: 'fb', twitter: 'tw' };
  const profile = person_data.profile || person_data.linkedin || person_data.twitter;
  let fn = profile.fullname || profile.displayName || '';
  let postfix = (profile.email || '').slice('-8');
  if (postfix) postfix = '@...' + postfix;
  let source = 'profile';
  Object.keys(_SERVICES).forEach(svc => {
    const sdata = person_data[svc] || person_data[lookup[svc]];
    if (sdata && sdata.username == profile.username) {
      postfix = svc; // lookup[svc] ||
      source = svc;
    }
  });
  return [(fn += ' (' + postfix + ')'), source];
};

export const searchPeople = ({ name }) => {
  return async (dispatch, getState) => {
    if (!name) {
      console.warn('searchPeople called, but missing name .... abort', name);
      return null;
    }

    if (getState().people?.isRequesting) {
      console.warn(
        'searchPeople in process, but called again! skip the call.... searching for: ',
        name
      );
      return null;
    }

    dispatch(searchPeopleRequestStart());

    console.log('[redux/people/actions] searchPeople for: ', name);

    const trimmedName = name.trim().toLowerCase();

    const payload = { query_name: 'aqlNameAutocomplete', data: { name_string: trimmedName } };
    const params = {
      payload,
      setLoading: () => {},
      setResponse: data => {
        // console.log('check response data format:', data?.result?.[0]?.matches);
        const peopleList = data?.result?.[0]?.matches?.map(person => {
          const pdata = person.data.profile || person.data.linkedin || person.data.twitter;
          let them = {
            _id: person._id,
            ...pdata
          };
          [them.fullname, them.source] = decorate_fullname(person.data);
          return them;
        });
        dispatch(setPeopleData(peopleList));
      },
      setResponseError: error => {
        dispatch(setPeopleError(error));
      }
    };
    nooCommandDirect('aql', params);
  };
};

export const clearPeople = () => ({ type: CLEAR_PEOPLE_DATA });
