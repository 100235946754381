import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import {
  Box,
  Flex,
  Link,
  HStack,
  VStack,
  FormLabel,
  Tooltip,
  Text,
  Image,
  UnorderedList,
  ListItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  OrderedList,
  Heading
} from '@chakra-ui/react';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import _ from 'lodash';
import styled from '@emotion/styled';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import useQuery from 'utils/useQuery';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import UserCard, { ServiceLink } from 'components/Segments/UserCard';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';

import UserAuth from 'components/User/UserAuth';
import ShowNetwork from 'components/Networks/ShowNetwork';
// import ShowD3Network from 'components/Networks/ShowD3Network';
import ShowForceNetwork from 'components/Networks/ShowForceNetwork';
import { graph_normalize } from 'utils/graphNormalize';
import { normalize_id, capitalize } from 'utils';
import ClaimPopover from '../Segments/ClaimPopover';
import ClaimBookmarklet from 'shared/constants/claimBookmarklet';
import _SERVICES from 'shared/constants/socialServices';
import { Claims } from 'components/Segments/UserCard';
import Videos from 'components/Segments/Videos';
import noo from 'api';
let _CLAIM_WINDOW = null;

const WhiteBox = styled(Box)`
  background-color: #fafafa;
  border: 1px solid #ccc;
`;
export const ImageConstrainer = styled.div`
  > img {
    max-width: 100px;
    border: 1px solid #000;
    margin-top: 10px;
  }
`;

const boxSx = {
  border: '1px solid #ccc',
  flex: '1 1 auto',
  paddingBottom: '4px'
};

const showVideo = () => {};

const onOpenService = svc => {
  _CLAIM_WINDOW = window.open(
    'https://www.linkedin.com',
    '_blank',
    'toolbar=yes,bookmarks,directories=yes'
  );
};
const testFocus = () => {
  if (_CLAIM_WINDOW) _CLAIM_WINDOW.focus();
};

const Instructions = props => {
  const available = Object.keys(_SERVICES)
    .map(svc => capitalize(svc))
    .join(', ');

  let links = [];
  let items = [];

  Object.entries(_SERVICES).forEach(pair => {
    const [svc, domain] = pair;
    const it = <ServiceLink svc={svc} />;
    items.push(
      <ServiceLink
        sx={{ border: '1px solid lightgrey' }}
        svc={svc}
        img={'https://standingwave.net/img/NN/serviceFavicons/' + svc + '_favicon.png'}
      />
    );
    // items.push(<span>, </span>);
  });

  return (
    <Accordion className='accordion' sx={boxSx} allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              <Text as='span' fontSize='lg' fontWeight={'bold'}>
                Step 1: Make sure your Bookmarks Bar is exposed.{' '}
              </Text>
              <Text as='span' fontWeight={'normal'}>
                (2 min)
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Make sure your Bookmarks bar is visible, like this <ArrowDownIcon /> .
          <Image
            width={'400px'}
            src='https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F76d28981-0aae-4412-903a-e59576d60d51_901x274.png'
          ></Image>
          If not, you need to <strong>expose it first</strong>.
          <VStack>
            <Image
              width={'400px'}
              src='https://standingwave.net/img/NN/GC_show_bookmarks_bar_firefox.gif'
            ></Image>
            <Image
              width={'300px'}
              src='https://standingwave.net/img/NN/GC_show_bookmarks_bar_chrome.gif'
            ></Image>
          </VStack>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              <Text as='span' fontSize='lg' fontWeight={'bold'}>
                Step 2: Add the GreenCheck bookmarklet to your Bookmarks bar{' '}
              </Text>
              <Text as='span' fontWeight={'normal'}>
                (1 min)
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          {props.bookmarklet}
          <Text>
            Drag the green bookmarklet above ^ to your browser bookmarks bar. Watch this gif.
            <Image src='https://standingwave.net/img/NN/GC_install_bookmarklet.gif'></Image>
          </Text>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              <Text as='span' fontSize='lg' fontWeight={'bold'}>
                Step 3: Click any of these services <ArrowDownIcon /> and click the Claim
                Bookmarklet there.{' '}
              </Text>
              <Text as='span' fontWeight={'normal'}>
                (2 min)
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>
            Click <ArrowDownIcon /> to open a new tab. <strong>Be sure you are logged in</strong>.
            Then <strong>click the Claim bookmarklet</strong>. there.
          </Text>
          <Text>
            <strong>On that new tab, click the Claim bookmarklet</strong> you just installed.
          </Text>
          <Flex>{items}</Flex>
          <Text>Example:</Text>
          <Image width={'400px'} src='https://standingwave.net/img/NN/GC_claim_demo.gif'></Image>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              <Text as='span' fontSize='lg' fontWeight={'bold'}>
                Step 4 : Return to this page and reload (command-r).{' '}
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>You should see your latest Claim at the top of your list.</Text>
        </AccordionPanel>
      </AccordionItem>{' '}
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              <Text as='span' fontSize='lg' fontWeight={'bold'}>
                Possible hiccups: Popups disabled on one or more service.{' '}
              </Text>
              <Text as='span' fontWeight={'normal'}>
                (1-5 min)
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>There will be a warning. </Text>
        </AccordionPanel>
      </AccordionItem>
      {/*       <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              <Text as='span' fontSize='lg' fontWeight={'bold'}>
                Tutorial video
              </Text>
              <Text as='span' fontWeight={'normal'}>
                (4 min)
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Box>
            <Text>Watch the whole process in the brief video.</Text>
            <Button onClick={showVideo}>View video</Button>
          </Box>
        </AccordionPanel>
      </AccordionItem> */}
    </Accordion>
  );
};

const ClaimProfile = props => {
  const { person_id } = useParams(); // onSubmit
  const { nooUser, currentNetworkData } = useNoo('ClaimProfile');
  const netname = currentNetworkData.id;
  const { nooCommandDirect, nooCommandResponseDirect } = useNooApi();
  const [localErrors, setLocalErrors] = useState(null);
  const [profileGraph, setProfileGraph] = useState({ profile: null, graph: null });
  const [profile, setProfile] = useState(null);
  const [token, setToken] = useState(null);
  const bookmarkletRef = useRef();
  const [bundles, setBundles] = useState({ netcoop: 'data_sandbox', newday: 'spacetime_0' });

  const personId = useMemo(() => {
    return person_id ? 'Persons/' + person_id : nooUser?.person?._id;
  }, [person_id, nooUser?.person?._id]);

  const readPersonToken = useCallback(
    async pid => {
      if (!token) {
        fetch(`/api/gc_encrypt/${pid}`)
          .then(async response => {
            if (response.ok) {
              const json = await response.json();
              if (json.token) {
                setToken(json.token);
              }
              return json;
            } else {
              console.log('gc token resp NOT OK', response);
            }
          })
          .catch(error => {
            console.log('there was an error getting token', error);
            return { error };
          });
      }
    },
    [token]
  );

  useEffect(() => {
    if (token && bookmarkletRef?.current) {
      let it = ClaimBookmarklet.replace('LOGGEDINUSERID', token);
      const bundle = bundles[netname] || 'noo_spacetime';
      it = it.replaceAll('noo_spacetime', bundle);
      // it = it.replace('https://greencheck.world', 'http://localhost:3001'); // for testing locally
      bookmarkletRef.current.href = it;
    }
  }, [netname, token, bookmarkletRef, bundles]);

  const myBookmarklet = useMemo(() => {
    if (personId) {
      const json = readPersonToken(personId.replace('Persons/', '')) || {};
      let it = ClaimBookmarklet.replace('LOGGEDINUSERID', json.token || personId);
      const bundle = bundles[netname] || 'noo_spacetime';
      it = it.replaceAll('noo_spacetime', bundle);
      return it;
    }
  }, [bundles, netname, personId, readPersonToken]);

  const showError = e => {
    alert(
      "You don't click this bookmarklet on this page. You install it from here, and click from the pages you want to claim."
    );
    e.preventDefault();
  };

  const bookmarklet = useMemo(() => {
    const image = '/public/images/greencheck_cropped.png';
    const bm_text = '';
    const prefix = location.host.indexOf('localhost') == 0 ? 'http://' : 'https://';
    const href = myBookmarklet;

    return (
      myBookmarklet && (
        <Tooltip label='Click-Drag this to your browser bookmark bar, and click it from your logged-in LinkedIn, Twitter, Github, Mastodon... pages.'>
          <Link
            href={href}
            ref={bookmarkletRef}
            color='white'
            display='inline-block'
            p='0 4px'
            backgroundColor={'lightgreen'}
            borderRadius='full'
            onClick={showError}
          >
            <HStack>
              <Image src={image} alt={bm_text} width={'25px'} />
              <Text color='black'>
                {netname == 'netcoop'
                  ? 'Net Co-op'
                  : netname == 'newday'
                  ? 'Spacetime'
                  : 'GreenCheck'}{' '}
                Claim
              </Text>
            </HStack>
          </Link>
        </Tooltip>
      )
    );
  }, [myBookmarklet, netname]);

  const available = Object.keys(_SERVICES)
    .map(svc => capitalize(svc))
    .join(', ');

  let links = [];
  let items = [];
  Object.entries(_SERVICES).forEach(pair => {
    const [svc, domain] = pair;
    items.push(<ServiceLink svc={svc} />);
    items.push(<span>, </span>);
  });
  return (
    <UserAuth unauthorized='signin'>
      <Heading as='h2' fontSize={'20px'}>
        Use this page to claim ownership of your various accounts.
      </Heading>
      {netname == 'netcoop' && <Text>Network Cooperative requires LinkedIn.</Text>}
      <Text>
        Browser only for now. Getting set up (installing the bookmarklet) only takes a minute or
        two. After that, each claim takes just a few seconds.
      </Text>
      <Accordion className='accordion' allowToggle>
        {' '}
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Text>
                <strong>Instructions</strong>
              </Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Instructions bookmarklet={bookmarklet}></Instructions>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Flex>
        {/* <Videos key='videos' /> */}
        <Box width='50%' minWidth={'300px'}>
          {profile && (
            <Flex sx={{ w: '100%' }}>
              <Heading as='h4'>{profile.fullname || profile.displayName}</Heading>
            </Flex>
          )}
          <Claims personId={personId} />
        </Box>
      </Flex>
    </UserAuth>
  );
};

export default ClaimProfile;
